import React, { useState } from "react";
import "./tooltip.css";

const Tooltip = ({ text, children }) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <div className="tooltip-container">
      {children}
      <div
        className="tooltip-trigger"
        onMouseEnter={() => setIsVisible(true)}
        onMouseLeave={() => setIsVisible(false)}
        onClick={() => setIsVisible(!isVisible)}
      >
        <i
          className="fas fa-info-circle text-primary"
          style={{ fontSize: 20 }}
        ></i>
      </div>
      {isVisible && <div className="tooltip-content">{text}</div>}
    </div>
  );
};

export default Tooltip;
