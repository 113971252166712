import { useState } from "react";
import Tooltip from "./Tooltip/Tooltip";

const CheckboxListGroupControl = ({
  label = false,
  value,
  change,
  name,
  k = -1,
  error = false,
  disabled = false,
  datas = false,
  dataIndex = "id",
  dataLabel = "name",
  className = "",
  required = false,
  inputClassName = "",
  dataLabelIndex = false,
  containerStyle = {},
  margin = " my-5 ",
  groups = [],
  allDefaultsText = "Sélectionner tous les choix par défaut",
}) => {
  const [isOpen, setIsOpen] = useState(groups.length > 0 ? groups[0].id : null);
  const [selectedQuestions, setSelectedQuestions] = useState({});
  const [transitioning, setTransitioning] = useState(false);

  const toggleAccordion = (groupId) => {
    if (transitioning) return; // Prevent interaction during transition
    // setIsOpen((i) => (i === groupId ? null : groupId));
    setIsOpen(groupId);
  };

  const handleCheckboxChange = (data, groupId, e, k) => {
    const newValue = data[dataIndex];
    const isChecked = e.target.checked;

    if (isChecked) {
      setSelectedQuestions((prevState) => ({
        ...prevState,
        [groupId]: data[dataLabel],
      }));

      const nextGroup = groups.find((group) => {
        if (group.id === groupId) return false;
        return (
          !selectedQuestions[group.id] &&
          datas.some(
            (d) => d.group === group.id && !value.includes(d[dataIndex])
          )
        );
      });

      if (nextGroup) {
        setTransitioning(true);

        setTimeout(() => {
          setIsOpen(null); // Collapse current group

          setTimeout(() => {
            setIsOpen(nextGroup.id);
            setTransitioning(false);

            const element = document.getElementById("heading" + nextGroup.id);
            const topOffset = 80; // Adjust this value for the desired gap

            if (element) {
              const elementPosition =
                element.getBoundingClientRect().top + window.scrollY;
              const offsetPosition = elementPosition - topOffset;

              window.scrollTo({
                top: offsetPosition,
                behavior: "smooth",
              });
            }
          }, 500); // Delay to open next group
        }, 500); // Delay to collapse current group
      }
    } else {
      setSelectedQuestions((prevState) => ({
        ...prevState,
        [groupId]: null,
      }));
    }

    if (k != -1) {
      change(
        {
          target: {
            name: name,
            value: newValue,
            formArray: e,
            isExclusive: data.isExclusive,
          },
        },
        k
      );
    } else {
      change({
        target: {
          name: name,
          value: newValue,
          formArray: e,
          isExclusive: data.isExclusive,
        },
      });
    }
  };

  const handleSelectAllDefaults = () => {
    const defaultAnswers = datas.filter((data) => data.isDefault);
    const defaultAnswerValues = defaultAnswers.map((data) => data[dataIndex]);
    change({
      target: {
        name: name,
        value: defaultAnswerValues,
        override: true,
      },
    });

    setSelectedQuestions(
      defaultAnswers.reduce((acc, data) => {
        acc[data.group] = data[dataLabel];
        return acc;
      }, {})
    );
  };

  return (
    <div
      style={containerStyle}
      className={"form-group " + className + " " + margin}
    >
      <label htmlFor={k !== -1 ? name + "-" + k : name} className="d-block">
        {label}
        {required && <span className="text-danger ml-1">*</span>}
      </label>
      {/* Select All Defaults Button */}
      {/* <button
        type="button"
        className="btn  shadow-none border mb-3 text-lowercase btn-input"
        onClick={handleSelectAllDefaults}
        disabled={disabled}
      >
        {allDefaultsText}
      </button> */}
      <div id="accordion" className="btn-group">
        {groups.map((group, gk) => {
          const selectedAnswerInGroup = datas.find(
            (data) => data.group === group.id && value.includes(data[dataIndex])
          );

          const isAnswered = !!selectedQuestions[group.id]; // Boolean for answered state

          return (
            <div className="" key={group.id}>
              <div
                className="d-flex w-100"
                style={{ gap: 10 }}
                id={"heading" + group.id}
              >
                <h5 className="mb-0 d-flex flex-fill">
                  <button
                    className={`btn btn-default btn-input d-flex flex-column justify-content-center align-items-start w-100
                        ${isOpen === group.id ? "opened" : ""}
                        `}
                    aria-expanded={isOpen === group.id ? "true" : "false"}
                    aria-controls={"collapse" + group.id}
                    type="button"
                    onClick={() => toggleAccordion(group.id)}
                    style={{
                      gap: 10,
                    }}
                  >
                    <div className="w-100 d-flex justify-content-between align-items-center">
                      <div className="d-flex align-items-center">
                        {/* Circle indicator */}
                        <div
                          className="indicator-circle"
                          style={{
                            width: 20,
                            minWidth: 20,
                            height: 20,
                            borderRadius: "50%",
                            border: isAnswered ? "none" : "2px solid #ccc",
                            backgroundColor: isAnswered
                              ? "green"
                              : "transparent",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            color: "white",
                            marginRight: 10,
                          }}
                        >
                          {isAnswered && <i className="fa fa-check" />}
                        </div>
                        {/* Group label */}
                        {group.label}
                      </div>
                      <i
                        className={
                          isOpen === group.id
                            ? `fa fa-caret-up`
                            : `fa fa-caret-down`
                        }
                      />
                    </div>
                    {selectedAnswerInGroup?.label ? (
                      <div
                        className={`d-flex flex-wrap align-items-center 
                          ${
                            selectedAnswerInGroup.isDefault
                              ? "text-secondary"
                              : "text-primary"
                          }
                          `}
                      >
                        <small
                          className=""
                          style={{ fontSize: 12, fontWeight: "500" }}
                        >
                          {selectedAnswerInGroup?.label || ""}
                        </small>
                      </div>
                    ) : (
                      <small
                        className="text-secondary"
                        style={{ fontSize: 12, fontWeight: "500" }}
                      >
                        Sélectionnez une réponse
                      </small>
                    )}
                  </button>
                </h5>
                {group.explication ? (
                  <Tooltip text={group.explication}>
                    <></>
                  </Tooltip>
                ) : (
                  <div style={{ width: 18 }}></div>
                )}
              </div>
              <div
                id={"collapse" + group.id}
                className={`checkboxgroup-accordion-content ${
                  isOpen === group.id ? "show" : ""
                }`}
                aria-labelledby={"heading" + group.id}
              >
                <div className="card-body pt-2">
                  {datas.map((data, dk) => {
                    if (data.group !== group.id) return null;
                    return (
                      <div
                        key={dk}
                        className="d-flex align-items-center "
                        style={{ gap: 10 }}
                      >
                        <div className={"my-3 custom-control custom-switch"}>
                          <input
                            type="checkbox"
                            className={"custom-control-input " + inputClassName}
                            disabled={disabled ? "disabled" : false}
                            name={name}
                            id={
                              k !== -1
                                ? "customSwitch" +
                                  data.group +
                                  dk +
                                  name +
                                  "-" +
                                  k
                                : "customSwitch" + data.group + dk + name
                            }
                            onChange={(e) =>
                              handleCheckboxChange(data, group.id, e, k)
                            }
                            checked={
                              value.includes(data[dataIndex])
                                ? "checked"
                                : false
                            }
                          />
                          <label
                            className="custom-control-label"
                            htmlFor={
                              k !== -1
                                ? "customSwitch" +
                                  data.group +
                                  dk +
                                  name +
                                  "-" +
                                  k
                                : "customSwitch" + data.group + dk + name
                            }
                          >
                            {dataLabelIndex
                              ? data[dataLabel][dataLabelIndex]
                              : data[dataLabel]}
                            {required && (
                              <span className="text-danger ml-1">*</span>
                            )}
                          </label>
                        </div>
                        {data.explication ? (
                          <Tooltip text={data.explication}>
                            <></>
                          </Tooltip>
                        ) : (
                          <div style={{ width: 18 }}></div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <small
        className={
          "form-text text-danger " + (error[name] ? "animated flash" : "")
        }
      >
        {error[name]}
      </small>
    </div>
  );
};

export default CheckboxListGroupControl;
